import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import routes from "./routes";


const Routes = (props) => {
  return (
    <Switch>
      {window.scrollTo({ top: 0, left: 0 })}
      {routes.map(({ component: Component, ...route }) => {
        return (
          <Route
            {...route}
            key={route.path}
            render={() => <Component {...props} />}
          />
        );
      })}
    </Switch>
  );
};

export default withRouter(Routes);
