import { all, takeEvery } from "redux-saga/effects";
import processAction from "../../utils/processAction";
// import { actionTypes as authActionTypes } from "./reducer";
import { actionTypes as propertyActionTypes } from "./reducer";
import * as services from "./service";

// GET FROM API

function* getContents(action) {
  yield processAction({
    ...action,
    service: services.getContents,
    success: propertyActionTypes.GET_CONTENTS_SUCCESS,
    failed: propertyActionTypes.GET_CONTENTS_FAILED,
  });
}

function* getMaintainedValues(action) {
  yield processAction({
    ...action,
    service: services.getMaintainedValues,
    success: propertyActionTypes.GET_MAINTAINEDVALUES_SUCCESS,
    failed: propertyActionTypes.GET_MAINTAINEDVALUES_FAILED,
  });
}

// POST TO API

function* postInquiry(action) {
  yield processAction({
    ...action,
    service: services.postInquiry,
    success: propertyActionTypes.POST_INQUIRY_SUCCESS,
    failed: propertyActionTypes.POST_INQUIRY_FAILED,
  });
}

function* postAppointment(action) {
  yield processAction({
    ...action,
    service: services.postAppointment,
    success: propertyActionTypes.POST_APPOINTMENT_SUCCESS,
    failed: propertyActionTypes.POST_APPOINTMENT_FAILED,
  });
}

function* postSubscription(action) {
  yield processAction({
    ...action,
    service: services.postSubscription,
    success: propertyActionTypes.POST_APPOINTMENT_SUCCESS,
    failed: propertyActionTypes.POST_APPOINTMENT_FAILED,
  });
}

function* postCareer(action) {
  yield processAction({
    ...action,
    service: services.postCareer,
    success: propertyActionTypes.POST_APPOINTMENT_SUCCESS,
    failed: propertyActionTypes.POST_APPOINTMENT_FAILED,
  });
}

function* searchProperties(action) {
  yield processAction({
    ...action,
    service: services.searchProperties,
    success: propertyActionTypes.SEARCH_PROPERTIES_SUCCESS,
    failed: propertyActionTypes.SEARCH_PROPERTIES_FAILED,
  });
}

function* selectingProperty(action) {
  yield processAction({
    ...action,
    service: services.selectingProperty,
    success: propertyActionTypes.SELECTING_PROPERTY_SUCCESS,
    failed: propertyActionTypes.SELECTING_PROPERTY_FAILED,
  });
}

function* getTestimonials(action) {
  yield processAction({
    ...action,
    service: services.getTestimonials,
    success: propertyActionTypes.GET_TESTIMONIALS_SUCCESS,
    failed: propertyActionTypes.GET_TESTIMONIALS_FAILED,
  });
}

function* getWebCareer(action) {
  yield processAction({
    ...action,
    service: services.getWebCareer,
    success: propertyActionTypes.GET_WEB_CAREER_SUCCESS,
    failed: propertyActionTypes.GET_WEB_CAREER_FAILED,
  });
}

function* selectingCareer(action) {
  yield processAction({
    ...action,
    service: services.selectingCareer,
    success: propertyActionTypes.SELECTING_WEB_CAREER_SUCCESS,
    failed: propertyActionTypes.SELECTING_WEB_CAREER_FAILED,
  });
}

function* getAwards(action) {
  yield processAction({
    ...action,
    service: services.getAwards,
    success: propertyActionTypes.GET_AWARDS_SUCCESS,
    failed: propertyActionTypes.GET_AWARDS_FAILED,
  });
}

function* watchLoginRequest() {
  yield all([
    // GET FROM API
    yield takeEvery(propertyActionTypes.GET_CONTENTS_REQUEST, getContents),
    yield takeEvery(
      propertyActionTypes.GET_MAINTAINEDVALUES_REQUEST,
      getMaintainedValues
    ),

    // POST TO API
    yield takeEvery(propertyActionTypes.POST_INQUIRY_REQUEST, postInquiry),
    yield takeEvery(
      propertyActionTypes.POST_APPOINTMENT_REQUEST,
      postAppointment
    ),
    yield takeEvery(
      propertyActionTypes.POST_SUBSCRIPTION_REQUEST,
      postSubscription
    ),

    yield takeEvery(propertyActionTypes.POST_CAREER_REQUEST, postCareer),
    yield takeEvery(propertyActionTypes.SEARCH_PROPERTIES_REQUEST, searchProperties),
    yield takeEvery(propertyActionTypes.SELECTING_PROPERTY_REQUEST, selectingProperty),
    yield takeEvery(propertyActionTypes.GET_TESTIMONIALS_REQUEST, getTestimonials),
    yield takeEvery(propertyActionTypes.GET_WEB_CAREER_REQUEST, getWebCareer),
    yield takeEvery(propertyActionTypes.SELECTING_WEB_CAREER_REQUEST, selectingCareer),
    yield takeEvery(propertyActionTypes.GET_AWARDS_REQUEST, getAwards),
  ]);
}

export default [watchLoginRequest()];
