import { combineReducers } from "redux";
// import { persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";

import dashboard from "../modules/dashboard/reducer";

// const authPersistConfig = {
//   key: "auth",
//   storage,
// };

export default combineReducers({
  dashboard,
});
