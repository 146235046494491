import { Col, Result, Row } from "antd";
import PropTypes from "prop-types";
import React from "react";
import "./index.css";


// set display name for component
const displayName = "CommonLoader";

// validate component properties
const propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.object,
};

const LoadingComponent = ({ isLoading, error, firstLoading, updating }) => {
  // Handle the loading state
  if (isLoading) {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        className="error"
        style={{ height: "80vh" }}
      >
        <Col align="center" style={{ flexGrow: 1 }}>
          <img
            alt="logo"
            onError={(broken) => (broken.target.src = `/images/rlc-logo.png`)}
            style={{ height: "25rem", width: "25rem", objectFit: "contain" }}
            src={`/images/rlc-logo.png`}
          />
        </Col>
      </Row>
    );
  }

  if (firstLoading) {
    return (
      <Row type="flex" justify="center" align="middle" className="error">
        <Col style={{ flexGrow: 1 }}>
          <Row>
            <Col align="center">
              <img
                alt="logo"
                onError={(broken) =>
                  (broken.target.src = `/images/rlc-logo.png`)
                }
                style={{
                  height: "15rem",
                  width: "15rem",
                  objectFit: "contain",
                }}
                src={`/images/rlc-logo.png`}
              />
            </Col>
            <Col>
              <Row>
                <Col
                  align="center"
                  style={{ fontSize: "1.5rem", color: "#222" }}
                >
                  Loading website for the first time.
                </Col>
                <Col align="center">This may take awhile...</Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  if (updating) {
    return (
      <Row type="flex" justify="center" align="middle" className="error">
        <Col style={{ flexGrow: 1 }} align="center">
          <img
            alt="logo"
            onError={(broken) => (broken.target.src = `/images/rlc-logo.png`)}
            style={{
              height: "15rem",
              width: "15rem",
              objectFit: "cover",
            }}
            src={`/images/rlc-logo.png`}
          />
        </Col>
      </Row>
    );
  }
  // Handle the error state
  if (error) {
    return (
      <Row type="flex" justify="center" align="middle" className="error">
        <Result
          status="500"
          title="We Apologize"
          subTitle="Sorry, there was a problem loading the page."
          style={{ flexGrow: 1 }}
        />
      </Row>
    );
  } else {
    return null;
  }
};

LoadingComponent.displayName = displayName;
LoadingComponent.propTypes = propTypes;

export default React.memo(LoadingComponent);
