import Loadable from "react-loadable";
import LoadingComponent from "../../commons/loader";

export default [
  {
    path: "/",
    exact: true,
    component: Loadable({
      loader: () => import("./home"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/properties/:location?/:unit?/:min?/:max?",
    exact: true,
    component: Loadable({
      loader: () => import("./properties"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/property/:brand?",
    exact: true,
    component: Loadable({
      loader: () => import("./property"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/contact-us",
    exact: true,
    component: Loadable({
      loader: () => import("./contact-us"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/news-&-promos",
    exact: true,
    component: Loadable({
      loader: () => import("./news-and-promos"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/news-&-promos/:title?",
    exact: true,
    component: Loadable({
      loader: () => import("./news-and-promos-view"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/testimonials",
    exact: true,
    component: Loadable({
      loader: () => import("./testimonials"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/testimonials/:title?",
    exact: true,
    component: Loadable({
      loader: () => import("./testimonials-view"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/book-appointment",
    exact: true,
    component: Loadable({
      loader: () => import("./book-appointment"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/homevestment-calculator",
    exact: true,
    component: Loadable({
      loader: () => import("./affordability-calculator"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/compare-properties",
    exact: true,
    component: Loadable({
      loader: () => import("./compare-properties"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/about-rlc",
    exact: true,
    component: Loadable({
      loader: () => import("./about-rlc"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/awards/:year?",
    exact: true,
    component: Loadable({
      loader: () => import("./awards"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/careers",
    exact: true,
    component: Loadable({
      loader: () => import("./careers"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/careers/:position?",
    exact: true,
    component: Loadable({
      loader: () => import("./careers-view"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/locations/:location?/:unit?/:min?/:max?",
    exact: true,
    component: Loadable({
      loader: () => import("./locations"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/location/:location?/:unit?/:min?/:max?",
    exact: true,
    component: Loadable({
      loader: () => import("./location"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/privacy-policy",
    exact: true,
    component: Loadable({
      loader: () => import("./privacy-policy"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/myrlc-app-privacy-policy",
    exact: true,
    component: Loadable({
      loader: () => import("./myrlc-app-privacy-policy"),
      loading: LoadingComponent,
    }),
  },
];
