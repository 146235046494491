// import {
//   setToken,
//   //  getToken, deleteToken
// } from "../../utils/token";

// Action Types
export const actionTypes = {
  GET_CONTENTS_REQUEST: "GET_CONTENTS_REQUEST",
  GET_CONTENTS_SUCCESS: "GET_CONTENTS_SUCCESS",
  GET_CONTENTS_FAILED: "GET_CONTENTS_FAILED",

  GET_MAINTAINEDVALUES_REQUEST: "GET_MAINTAINEDVALUES_REQUEST",
  GET_MAINTAINEDVALUES_SUCCESS: "GET_MAINTAINEDVALUES_SUCCESS",
  GET_MAINTAINEDVALUES_FAILED: "GET_MAINTAINEDVALUES_FAILED",

  POST_INQUIRY_REQUEST: "POST_INQUIRY_REQUEST",
  POST_INQUIRY_SUCCESS: "POST_INQUIRY_SUCCESS",
  POST_INQUIRY_FAILED: "POST_INQUIRY_FAILED",

  POST_APPOINTMENT_REQUEST: "POST_APPOINTMENT_REQUEST",
  POST_APPOINTMENT_SUCCESS: "POST_APPOINTMENT_SUCCESS",
  POST_APPOINTMENT_FAILED: "POST_APPOINTMENT_FAILED",

  POST_SUBSCRIPTION_REQUEST: "POST_SUBSCRIPTION_REQUEST",
  POST_SUBSCRIPTION_SUCCESS: "POST_SUBSCRIPTION_SUCCESS",
  POST_SUBSCRIPTION_FAILED: "POST_SUBSCRIPTION_FAILED",

  POST_CAREER_REQUEST: "POST_CAREER_REQUEST",
  POST_CAREER_SUCCESS: "POST_CAREER_SUCCESS",
  POST_CAREER_FAILED: "POST_CAREER_FAILED",

  SEARCH_PROPERTIES_REQUEST: "SEARCH_PROPERTIES_REQUEST",
  SEARCH_PROPERTIES_SUCCESS: "SEARCH_PROPERTIES_SUCCESS",
  SEARCH_PROPERTIES_FAILED: "SEARCH_PROPERTIES_FAILED",

  SELECTING_PROPERTY_REQUEST: "SELECTING_PROPERTY_REQUEST",
  SELECTING_PROPERTY_SUCCESS: "SELECTING_PROPERTY_SUCCESS",
  SELECTING_PROPERTY_FAILED: "SELECTING_PROPERTY_FAILED",

  GET_TESTIMONIALS_REQUEST: "GET_TESTIMONIALS_REQUEST",
  GET_TESTIMONIALS_SUCCESS: "GET_TESTIMONIALS_SUCCESS",
  GET_TESTIMONIALS_FAILED: "GET_TESTIMONIALS_FAILED",

  GET_WEB_CAREER_REQUEST: "GET_WEB_CAREER_REQUEST",
  GET_WEB_CAREER_SUCCESS: "GET_WEB_CAREER_SUCCESS",
  GET_WEB_CAREER_FAILED: "GET_WEB_CAREER_FAILED",

  SELECTING_WEB_CAREER_REQUEST: "SELECTING_WEB_CAREER_REQUEST",
  SELECTING_WEB_CAREER_SUCCESS: "SELECTING_WEB_CAREER_SUCCESS",
  SELECTING_WEB_CAREER_FAILED: "SELECTING_WEB_CAREER_FAILED",

  GET_AWARDS_REQUEST: "GET_AWARDS_REQUEST",
  GET_AWARDS_SUCCESS: "GET_AWARDS_SUCCESS",
  GET_AWARDS_FAILED: "GET_AWARDS_FAILED",
}

// Action creators
export const actionCreators = {
  // Get from API

  getContents: () => ({ type: actionTypes.GET_CONTENTS_REQUEST }),
  getMaintainedValues: () => ({
    type: actionTypes.GET_MAINTAINEDVALUES_REQUEST,
  }),

  // Post to API

  postInquiry: (params) => ({
    type: actionTypes.POST_INQUIRY_REQUEST,
    params,
  }),

  postAppointment: (params) => ({
    type: actionTypes.POST_APPOINTMENT_REQUEST,
    params,
  }),

  postSubscription: (params) => ({
    type: actionTypes.POST_SUBSCRIPTION_REQUEST,
    params,
  }),

  postCareer: (params) => ({
    type: actionTypes.POST_CAREER_REQUEST,
    params,
  }),

  searchProperties: (params) => ({
    type: actionTypes.SEARCH_PROPERTIES_REQUEST,
    params,
  }),

  selectingProperty: (params) => ({
    type: actionTypes.SELECTING_PROPERTY_REQUEST,
    params,
  }),

  getTestimonials: (params) => ({
    type: actionTypes.GET_TESTIMONIALS_REQUEST,
    params,
  }),

  getWebCareer: (params) => ({
    type: actionTypes.GET_WEB_CAREER_REQUEST,
    params,
  }),

  selectingCareer: (params) => ({
    type: actionTypes.SELECTING_WEB_CAREER_REQUEST,
    params,
  }),

  getAwards: (params) => ({
    type: actionTypes.GET_AWARDS_REQUEST,
    params,
  }),
};

// Reducer
const initialState = {
  contentsIsLoading: false,
  maintainedValuesIsLoading: false,
  projectInfo: [],
  contents: null,
  maintainedValues: null,
  sending: false,
  searching: false,
  searchData: [],
  selecting: false,
  selectedPropertyData: [],
  testimonialsData: [],
  webCareerData: [],
  loadingWebCareer: false,
  loadingTestimonials: false,
  selectedCareerData: [],
  loadingSelectedCareer: false,
  awardsData: [],
  loadingAwards: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // GET FROM API

    case actionTypes.GET_CONTENTS_SUCCESS:
      return Object.assign({}, state, {
        contentsIsLoading: false,
        error: null,
        contents: action.payload,
      });

    case actionTypes.GET_CONTENTS_REQUEST:
      return Object.assign({}, state, {
        contentsIsLoading: true,
        error: null,
      });

    case actionTypes.GET_CONTENTS_FAILED:
      return Object.assign({}, state, {
        contentsIsLoading: false,
        error: null,
      });

    case actionTypes.GET_MAINTAINEDVALUES_SUCCESS:
      return Object.assign({}, state, {
        maintainedValuesIsLoading: false,
        error: null,
        maintainedValues: action.payload,
      });

    case actionTypes.GET_MAINTAINEDVALUES_REQUEST:
      return Object.assign({}, state, {
        maintainedValuesIsLoading: true,
        error: null,
      });

    case actionTypes.GET_MAINTAINEDVALUES_FAILED:
      return Object.assign({}, state, {
        maintainedValuesIsLoading: false,
        error: null,
      });

    // POST TO API

    case actionTypes.POST_INQUIRY_SUCCESS:
      return Object.assign({}, state, {
        sending: false,
        error: null,
      });

    case actionTypes.POST_INQUIRY_REQUEST:
      return Object.assign({}, state, {
        sending: true,
        error: null,
      });

    case actionTypes.POST_INQUIRY_FAILED:
      return Object.assign({}, state, {
        sending: false,
        error: null,
      });

    case actionTypes.POST_APPOINTMENT_SUCCESS:
      return Object.assign({}, state, {
        sending: false,
        error: null,
      });

    case actionTypes.POST_APPOINTMENT_REQUEST:
      return Object.assign({}, state, {
        sending: true,
        error: null,
      });

    case actionTypes.POST_APPOINTMENT_FAILED:
      return Object.assign({}, state, {
        sending: false,
        error: null,
      });

    case actionTypes.POST_SUBSCRIPTION_SUCCESS:
      return Object.assign({}, state, {
        sending: false,
        error: null,
      });

    case actionTypes.POST_SUBSCRIPTION_REQUEST:
      return Object.assign({}, state, {
        sending: true,
        error: null,
      });

    case actionTypes.POST_SUBSCRIPTION_FAILED:
      return Object.assign({}, state, {
        sending: false,
        error: null,
      });

    case actionTypes.POST_CAREER_SUCCESS:
      return Object.assign({}, state, {
        sending: false,
        error: null,
      });

    case actionTypes.POST_CAREER_REQUEST:
      return Object.assign({}, state, {
        sending: true,
        error: null,
      });

    case actionTypes.POST_CAREER_FAILED:
      return Object.assign({}, state, {
        sending: false,
        error: null,
      });

    case actionTypes.SEARCH_PROPERTIES_REQUEST:
      return Object.assign({}, state, {
        searching: true,
        error: null,
        searchData: []
      });

    case actionTypes.SEARCH_PROPERTIES_SUCCESS:
      return Object.assign({}, state, {
        searching: false,
        error: null,
        searchData: action.payload,
      });

    case actionTypes.SEARCH_PROPERTIES_FAILED:
      return Object.assign({}, state, {
        searching: false,
        searchData: [],
        error: action.payload,
      });

    case actionTypes.SELECTING_PROPERTY_REQUEST:
      return Object.assign({}, state, {
        selecting: true,
        error: null,
        selectedPropertyData: []
      });

    case actionTypes.SELECTING_PROPERTY_SUCCESS:
      return Object.assign({}, state, {
        selecting: false,
        error: null,
        selectedPropertyData: action.payload,
      });

    case actionTypes.SELECTING_PROPERTY_FAILED:
      return Object.assign({}, state, {
        selecting: false,
        selectedPropertyData: [],
        error: action.payload,
      });

    case actionTypes.GET_TESTIMONIALS_REQUEST:
      return Object.assign({}, state, {
        loadingTestimonials: true,
        error: null,
        testimonialsData: []
      });

    case actionTypes.GET_TESTIMONIALS_SUCCESS:
      return Object.assign({}, state, {
        loadingTestimonials: false,
        error: null,
        testimonialsData: action.payload,
      });

    case actionTypes.GET_TESTIMONIALS_FAILED:
      return Object.assign({}, state, {
        loadingTestimonials: false,
        testimonialsData: [],
        error: action.payload,
      });

    case actionTypes.GET_WEB_CAREER_REQUEST:
      return Object.assign({}, state, {
        loadingWebCareer: true,
        error: null,
        webCareerData: []
      });

    case actionTypes.GET_WEB_CAREER_SUCCESS:
      return Object.assign({}, state, {
        loadingWebCareer: false,
        error: null,
        webCareerData: action.payload,
      });

    case actionTypes.GET_WEB_CAREER_FAILED:
      return Object.assign({}, state, {
        loadingWebCareer: false,
        webCareerData: [],
        error: action.payload,
      });

    case actionTypes.SELECTING_WEB_CAREER_REQUEST:
      return Object.assign({}, state, {
        loadingSelectedCareer: true,
        error: null,
        selectedCareerData: []
      });

    case actionTypes.SELECTING_WEB_CAREER_SUCCESS:
      return Object.assign({}, state, {
        loadingSelectedCareer: false,
        error: null,
        selectedCareerData: action.payload,
      });

    case actionTypes.SELECTING_WEB_CAREER_FAILED:
      return Object.assign({}, state, {
        loadingSelectedCareer: false,
        selectedCareerData: [],
        error: action.payload,
      });

    case actionTypes.GET_AWARDS_REQUEST:
      return Object.assign({}, state, {
        loadingAwards: true,
        error: null,
        awardsData: []
      });

    case actionTypes.GET_AWARDS_SUCCESS:
      return Object.assign({}, state, {
        loadingAwards: false,
        error: null,
        awardsData: action.payload,
      });

    case actionTypes.GET_AWARDS_FAILED:
      return Object.assign({}, state, {
        loadingAwards: false,
        awardsData: [],
        error: action.payload,
      });

    default:
      return state;
  }
};
