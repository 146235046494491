// import API from "../../utils/api";
import request from "../../utils/api";

// Get from API

export const getContents = () => {
  return request("get-contents");
};

export const getMaintainedValues = () => {
  return request("maintained-values");
};

// Post to API

export const postInquiry = (payload) => {
  return request("v1/web/inquiry", {
    method: "post",
    body: payload,
  });
};

export const postAppointment = (payload) => {
  return request("v1/web/appointment", {
    method: "post",
    body: payload,
  });
};

export const postSubscription = (payload) => {
  return request("v1/web/subscribe", {
    method: "post",
    body: payload,
  });
};

export const postCareer = (payload) => {
  return request("v1/web/application/careers", {
    method: "post",
    body: payload,
  });
};

export const searchProperties = (payload) => {
  return request("search-properties", {
    method: "post",
    body: payload,
  });
};

export const selectingProperty = (payload) => {
  return request("selecting-property", {
    method: "post",
    body: payload,
  });
};

export const getTestimonials = () => {
  return request("get-testimonials");
};

export const getWebCareer = () => {
  return request("get-web-career");
};

export const selectingCareer = (payload) => {
  return request(`get-web-career`, {
    method: "get",
    body: payload,
  });
};

export const getAwards = () => {
  return request("get-awards");
};
// export const selectingCareer = (payload) => {
//   return request(`get-web-career${payload.id ? `?id=${payload.id}` : ''}`, {
//     method: "get",
//     body: payload,
//   });
// };