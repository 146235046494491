import { applyMiddleware, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers";
import sagas from "./sagas";

const sagaMiddleware = createSagaMiddleware();

export default function (initialState = {}) {
  // Middleware and Enhancers
  const enhancers = [applyMiddleware(sagaMiddleware)];

  // Persisting state
  const persistConfig = {
    key: "root",
    storage,
    blacklist: [
      'searchData',
      'contentsIsLoading',
      'maintainedValuesIsLoading',
      'searching',
      'selecting'
    ]
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = createStore(
    persistedReducer,
    initialState,
    compose(...enhancers)
  );
  const persistor = persistStore(store);

  // hot reloading reducers
  // if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  //     module.hot.accept('./reducers', () => {
  //         const nextReducer = require('./reducers').default // eslint-disable-line global-require
  //         store.replaceReducer(nextReducer)
  //     })
  // }

  sagaMiddleware.run(sagas);

  return { store, persistor };
}
