import { BackTop, Button, Col, Icon, Layout, notification, Row } from "antd";
import React, { PureComponent } from "react";
import Loadable from "react-loadable";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import "./App.css";
import LoadingComponent from "./commons/loader";
import { actionCreators as brandsActionCreators } from "./modules/dashboard/reducer";
import Routes from "./routes";
import { Helmet } from 'react-helmet';

const loader = () => <div></div>;

const MainHeader = Loadable({
  loader: () => import("./modules/commons/header/index"),
  loading: loader,
});

const MainFooter = Loadable({
  loader: () => import("./modules/commons/footer/index"),
  loading: loader,
});

const PopupCookies = Loadable({
  loader: () => import("./modules/commons/popup-promos/index"),
  loading: loader,
});

const { Content } = Layout;

class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      mainDrawerVisible: false,
      firstLoading: false,
      popupPromosVisible: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  mainDrawerVisibility = (visible) => {
    this.setState({
      mainDrawerVisible: visible,
    });
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    this.props.getContents();
    this.props.getMaintainedValues();

    if (sessionStorage.getItem("popupShow") === null) {
      this.setState({
        popupPromosVisible: true,
      });
    }

    if (sessionStorage.getItem("cookieShow") === null) {
      notification.open({
        key: 1,
        message: `This website uses cookies`,
        duration: 0,
        onClose: this.handleCloseCookie,
        description: (
          <Row>
            <Col>
              We use cookies to make our website work better and improve your
              online experience. By continuing to browse this website you hereby
              accept the use of cookies. For more info, see our{" "}
              <a href="/privacy-policy">Privacy Policy</a>
            </Col>
          </Row>
        ),
        btn: (
          <Button
            type="ghost"
            onClick={(value) => this.handleCloseCookie(value)}
          >
            I Agree
          </Button>
        ),
        placement: "bottomRight",
      });
    }
  }
  

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  handleCloseCookie = () => {
    sessionStorage.setItem("cookieShow", true);
    notification.close(1);
  };

  handleClosePopup = () => {
    sessionStorage.setItem("popupShow", false);
    this.setState({
      popupPromosVisible: false,
    });
  };

  render() {
    const {
      contents,
      maintainedValues,
      contentsIsLoading,
      maintainedValuesIsLoading,
    } = this.props;
    const { popupPromosVisible, width } = this.state;

    const tablet = width < 768;

    const isLoading = contentsIsLoading || maintainedValuesIsLoading;

    const noData = !contents || !maintainedValues;

    if (noData) {
      return <LoadingComponent firstLoading={isLoading} />;
    }

    let activeURL = this.props.location.pathname.split("/")[1];

    return (
      <Layout className="dashboard">
        <MainHeader activeURL={activeURL} contents={contents} tablet={tablet} />
        <Content>
          <Routes tablet={tablet} />
        </Content>
        <MainFooter
          contents={contents}
          postSubscription={this.props.postSubscription}
          sending={this.props.sending}
          tablet={tablet}
        />
        <BackTop>
          <Icon type="up" />
        </BackTop>
        <PopupCookies
          visible={popupPromosVisible}
          popupPromos={contents.web_popup_promos}
          onClose={this.handleClosePopup}
          tablet={tablet}
        />
      </Layout>
    );
  }
}

const mapStateToProps = (state, ownState) => ({
  contentsIsLoading: state.dashboard.contentsIsLoading,
  maintainedValuesIsLoading: state.dashboard.maintainedValuesIsLoading,
  contents: state.dashboard.contents,
  maintainedValues: state.dashboard.maintainedValues,
  sending: state.dashboard.sending,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(brandsActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(React.memo(App)));
